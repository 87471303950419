import { useLocation } from "react-router-dom"
import { allowedSearch, searchRule } from "../funcs"

export const TopPage = (props: any) => {
    const dictData: any[] = props.dict

    const search = useLocation().search
    const query = new URLSearchParams(search)
    const tag = query.get('tag')
    const value = decodeURIComponent(query.get('value')!)
    const rule = query.get('rule')

    let filtered = dictData
    if (tag && allowedSearch.includes(tag) && Object.keys(searchRule).includes(rule!)) {
        filtered = filterDict(dictData, tag, value!, rule!)
    } else if (tag) { //不正な入力が行われた場合
        filtered = []
    }

    const infomation = tag
        ? <>
            <p>
                <b>{filtered.length}</b>件の雰字が見つかりました。
            </p>
            <h2>検索結果</h2>
        </>
        : <>
            <p>
                人工言語「
                <a href="https://phunworld.miraheze.org/wiki/%E9%9B%B0%E8%AA%9E" target="_blank">雰語</a>
                」で使われる「雰字」の字典サイトです。現在<b>{filtered.length}</b>文字が収録されています。
            </p>
            <h2>雰字一覧</h2>
        </>
        

    return (
        <div className='topWrapper'>
            {infomation}

            <div className='charaList'>
                {filtered.map(v => 
                    <a href={`/chara/${v.id}`}><div className='charaLink phun'>{v.chara}</div></a>
                )}
            </div>
        </div>
    )
}

const filterDict = (dict: any[], tag: string, value: string, rule: string) => {
    const [lang, prop] = tag.split("-")
    return dict.filter(e => {
        let judge = false

        try {
            let sample = ""
            if (lang == "qo") { //雰字 (転写など、メタ情報なので最上位にある)
                sample = e[prop]
            } else {
                sample = e.langs[lang][prop]
            }

            judge = matchRule(rule, sample, value)
        } catch (e) {
            return false
        }

        return judge
    })
}

const matchRule = (rule: string, text: string, index: string) => {
    switch (rule) {
        case "part":
            return text.includes(index)
        case "start":
            return text.startsWith(index)
        case "end":
            return text.endsWith(index)
        case "perfect":
            return text == index
        case "reg":
            return text.match(index) !== null
        default:
            return false
    }
}