import React, { useContext, useState, useEffect } from 'react';
import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";

import { Header, Footer } from './parts/fixed';

import { CharaPage } from './parts/Chara';
import { TopPage } from './parts/top';
import { NotFound } from './parts/404';

import { FetchDictData } from './funcs';

const fetchURL = "https://kaeru2193.github.io/Phun-Resources/dict/phun-chara.json"

const App = () => {
  const [body, setBody] = useState(<></>);

  useEffect(() => {
    const access = async () => {
      try {
        const json = await FetchDictData(fetchURL);

        setBody(
          <Main dict={json} />
        )
      } catch (error) {
        setBody(
          <div>
            データ取得に失敗しました。
          </div>
        )
      }
    }

    access()
  }, []);

  return (
    body
  )
}

const Main = (props: any) => {
  return (
    <div className='contentWrapper'>
      <Header/>
      <div className="main">
        <BrowserRouter>
          <Routes>
            <Route
              path={`${process.env.PUBLIC_URL}/`}
              element={
                <TopPage dict={props.dict} />
              }
            />
            <Route
              path={`${process.env.PUBLIC_URL}/chara/:id`}
              element={
                <CharaPage dict={props.dict} />
              }
            />
            <Route path={`${process.env.PUBLIC_URL}*`} element={<NotFound />} />
          </Routes>
        </BrowserRouter>
      </div>
      <Footer/>
    </div>
  );
}

export default App;
