import { useParams } from "react-router-dom";
import { NotFound } from './404';
import { langNames } from "../funcs";

export const CharaPage = (props: any) => {
    const params = useParams();
    const charaID = Number(params.id)

    const dictData: any[] = props.dict
    const matchData: any = dictData.find(v => v.id == charaID)

    const prev = <PrevButton dict={dictData} id={charaID}/>
    const next = <NextButton dict={dictData} id={charaID}/>

    if (matchData !== undefined) {
        return (
            <div className='pageWrapper'>
                <CharaInfo data={matchData} prev={prev} next={next}/>
                <CharaLangs data={matchData}/>
            </div>
        )
    } else {
        return <NotFound />
    }
}

const CharaInfo = (props: any) => {
    const data: any = props.data

    return (
        <div className='info'>
            <div className="moveLink">
                {props.prev}
                {props.next}
            </div>
            <div className='charaFrame'>
                <span className='mainChara phun'>{data?.chara}</span>
            </div>
            <div><span className='kanji'>{data?.chara}</span></div>
            <table className='dataTable'>
                <tbody>
                    <tr>
                        <th>PhunID</th>
                        <td>{data.id}</td>
                    </tr>
                    <tr>
                        <th>画数</th>
                        <td>{data.strokes}</td>
                    </tr>
                    <tr>
                        <th>部首</th>
                        <td>{data.radical}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}

const CharaLangs = (props: any) => {
    const data: any = props.data.langs
    console.log(data)

    return (
        <div className='langs'>
            {Object.keys(data).map((v, idx) => {
                const setLink = (links: any[]) => {
                    if (links) {
                        return (
                        <div className="langLinks">
                            {links.map((l: any, idx: number) =>
                                <span><a href={l.url} key={idx}>{l.name}</a></span>
                            )}
                        </div>
                        )
                    }
                }
                
                return (
                <div className='langData' key={"l" + idx}>
                    <h2>{langNames[v]}</h2>
                    <div>
                        <div className="dataRow">
                            <div className="dataLabel">発音</div>
                            <div className="data">{data[v].pron}</div>
                        </div>
                        <div className="dataRow">
                            <div className="dataLabel">意味</div>
                            <div className="data">{data[v].mean}</div>
                        </div>
                    </div>
                    {setLink(data[v].links)}
                </div>)
            })}
        </div>
    )
}

const PrevButton = (props: any) => {
    const data = props.dict.find((e: any) => e.id == props.id - 1)
    return data
        ? <span className="prevButton"><a href={`/chara/${data.id}`}>{`< `}<span className="phun">{data.chara}</span></a></span>
        : <span className="prevButton disabled"><span>{`< -`}</span></span>
}

const NextButton = (props: any) => {
    const data = props.dict.find((e: any) => e.id == props.id + 1)
    return data
        ? <div className="nextButton"><a href={`/chara/${data.id}`}><span className="phun">{data.chara}</span>{` >`}</a></div>
        : <div className="nextButton disabled"><span>{`- >`}</span></div>
}