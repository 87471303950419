export async function FetchDictData(url: string) {
    try {
        const res = await fetch(url);

        if (!res.ok) {
            throw new Error("データの取得に失敗しました");
        }

        const data = await res.json();
        return data
    } catch (error) {
        console.error(error);
        throw error
    }
}

export const langNames: any = {
    "qo": "雰字",
    "pn": "雰語",
    "mpn": "中古雰語",
    "tp": "トキポナ"
}

export const propNames: any = {
    "chara": "漢字翻字",
    "id": "PhunID",
    "pron": "発音",
}

export const allowedSearch: string[] = [
    "qo-chara",
    "qo-id",
    "pn-pron",
    "mpn-pron",
    "tp-pron",
]

export const searchRule: any = {
    "part": "部分一致",
    "start": "前方一致",
    "end": "後方一致",
    "perfect": "完全一致",
    "reg": "正規表現"
}