import { useState } from "react"
import { useNavigate } from "react-router-dom"
import { allowedSearch, langNames, propNames, searchRule } from "../funcs"

export const Header = () => {
    const [tag, setTag] = useState("qo-chara")
    const [search, setSearch] = useState("")
    const [rule, setRule] = useState("part")

    return (
        <div className="header">
            <div className="headerBox">
                <div className="logoBox">
                    <a href="/" className="logo">
                        <img src="/toplogo.png" className="logoImg"/>
                    </a>
                </div>
                <SearchBox tag={[tag, setTag]} search={[search, setSearch]} rule={[rule, setRule]}/>
            </div>
        </div>
    )
}

export const Footer = () => {
    return (
        <div className='footer'>
            © <a href='https://kaeru2193.net'>かえる</a> 2023-2024
        </div>
    )
}

const SearchBox = (props: any) => {
    const [tag, setTag] = props.tag
    const [search, setSearch] = props.search
    const [rule, setRule] = props.rule

    const searchProcess = () => {
        window.location.href = `/?tag=${tag}&value=${encodeURIComponent(search)}&rule=${rule}`
    }

    const enterSearch  = (e: any) => {
        if (e.key == "Enter") {
            searchProcess()
        }
    }

    return (
        <div className="searchBox">
            <input className="textInput"
                type="text"
                defaultValue={search}
                onChange={(e) => {setSearch(e.target.value)}}
                onKeyDown={(e) => {if (e.key == "Enter") { searchProcess() }}}
                placeholder="書尋事在之、、"
            />
            <select className="tagSelect"
                defaultValue={tag}
                onChange={(e) => setTag(e.target.value)}
            >
                {allowedSearch.map((t, idx) => {
                    const [lang, prop] = t.split("-")
                    const optionName = `${propNames[prop]} (${langNames[lang]})`
                    return <option key={idx} value={t}>{optionName}</option>
                })}
            </select>
            <select className="ruleSelect"
                defaultValue={rule}
                onChange={(e) => setRule(e.target.value)}
            >
                {Object.keys(searchRule).map((r, idx) => 
                    <option key={idx} value={r}>{searchRule[r]}</option>
                )}
            </select>
            <button className="searchButton"
                onClick={() => searchProcess()}
            ><span className="phun">尋</span></button>
        </div>
    )
}